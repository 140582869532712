@import "./vars.less";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background:#e5e5e5;
    margin: 0;
    padding: 0;
    font-family: "PingFang SC" ,sans-serif;
}

.App {
    text-align: center;

    >* {
        margin: 0px auto;
    }
}

.send,
.list {
    width: 370px;
}

.send {
    >* {
        display: block;
        width: 100%;
        margin: 10px auto;
        padding: 10px;
        border-radius: 5px;
        font-size: 16px;
        border: none;
        outline: none;
    }

    >textarea {
        height: 80px;
        background: #f7f7f7;
        border: #eeeeee 1px solid;

        &:focus {
            background: #fff;
            border-color: #ccc;
        }
    }

    >button {
        background: #215fa4;
        color: white;
        cursor: pointer;

        &:hover {
            background: #4b80bb;
        }
    }
}

.list {
    list-style: none;
    border-radius: 5px;
    padding: 10px;
    background: #f2f2f2;

    >li {
        margin-bottom: 10px;
        padding: 10px;
        background: #fff;
        line-height: 1.5em;
        border-radius: 5px;

        >.content {
            font-size: 14px;
            text-align: left;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        >.time {
            font-size: 12px;
            color: #4b80bb;
            text-align: right;
        }

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

}

// pages :
.SectionContact {
    margin:12px auto;
    background-color: #ffffff99;
    //flex-basis: 100%;
    padding-bottom: 50px;
    padding-top: 30px;

    /*display: flex;*/
    /*flex-direction:column;*/
    /*justify-content:space-around;*/
    /*align-items:center;*/
}
.SectionContactContainer{
    color: #00040D;
    font-size: 20px;
    line-height: 1em;
    padding-top: 30px;
    /*text-shadow: 1px 1px 0px #fff;*/

    text-shadow:-1px 0 white,
    0 1px white,
    1px 0 white,
        0 -1px white;

}
/* 1080~  */
@media screen and (min-width: 1080px )
{
    .SectionContactContainer{
        margin:0 auto;
        //display:flex;
        //flex-direction:column;
        //justify-content:flex-start;
        //align-items:center;

    }
    .SectionContact {
        margin: auto;
        width: 1080px;
        font-size: 26px;
        border-radius: 20px;
        backdrop-filter: blur(10px);
    }
}

.SectionContact .hr {
    width: 87%;
    height: 2px;
    margin: 10px auto;
    background: transparent url("./img/hr.png") 0 0 repeat-x;
}

.SectionContact h1 {
    display: block;
    font-size: 1.8em;
    font-weight:bold;
    padding:0;
    margin: 20px auto;
}
.SectionContact .p{
    margin: 12px auto 18px;
}
.SectionContact .p p{
    margin: 0 0;
    font-size: 1em;
    line-height:1.5em;
    font-weight:bold;
}
.SectionContactContainer .link{
    margin-top: 34px;
    display: flex;
    flex-direction:row;
    justify-content:space-around;
    align-items:center;
    color: #00040D;
}
.SectionContactContainer .link a{
    color: #00040D;
    text-decoration: none;
}


// section - Intro
.SectionIntro{
    text-align: justify;
    color: #00040D;
    font-size: 20px;
    line-height: 1em;
    padding-top: 30px;
    padding-bottom: 50px;
    margin:0 0;
    background-color: #ffffff99;
    flex-basis: 100%;
}
.SectionIntro.alt{
    text-align: right;
}
.SectionHalf{
    width: 50%;
    flex-basis: 100%;
}
.SectionContainer{
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap: wrap;
    justify-content:space-between;
}
/* 1080~  (PC) */
@media screen and (min-width: 1080px )
{
    .SectionContainer{
        width: 1080px;
        margin:12px auto;
    }
    .SectionIntro {
        //width: 1080px;
        font-size: 26px;
        margin:20px 0;
        border-radius: 20px;
        backdrop-filter: blur(10px);
    }
    .SectionHalf{
        flex-basis: calc(50% - 20px); /* 考虑一定的间距，这里假设间距为 20px，每个元素平分一半宽度减去间距的一半 */
    }
}
.SectionIntro .hr {
    /*width: 87%;*/
    height: 2px;
    margin: 10px 20px;
    background: transparent url("./img/hr.png") 0 0 repeat-x;
}
.SectionIntro h1 {
    display: block;
    font-size: 1.8em;
    font-weight:bold;
    padding:0;
    margin: 20px 40px;
}
.SectionIntro .p h3{
    font-weight:bold;
    font-size: 0.7em;
    color: #00040D;
    line-height:1em;
    padding:0;margin:0;
    text-align: center;
}
.SectionIntro .p{
    text-align: justify;
    margin:27px 40px ;
    clear: both;
}
.SectionIntro .p p{
    font-size: 0.7em;
    color: #00040D;
    margin: 0 0 10px 0;
    line-height:1.8em;
}
.SectionIntro .p p.tag    {
    font-size: 0.7em;
    color: #00040D;
    margin-bottom: 2px;
    line-height:1.5em;
    text-align: left;
}
.SectionIntro .p p.tag i {
    width: 14px;
}
.SectionIntro .p p.tag span:before {
    content:" ";
}
.SectionIntro .p p.tag span{
    color: #0098CB;
}


// section - BLOG
.SectionBlog{
    margin-top: 40px;
    position: relative;
    //background: #ffffffcc;
    height: calc(100vh - 100px);
    //backdrop-filter: blur(10px);

    .blog-thumb{
        height:auto;
        //backdrop-filter: blur(10px);
        border-radius: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: blur(10px);
    }
    h1{
        background: #ffffffcc;
        backdrop-filter: blur(10px);
        padding: 10px 20px;
        border-radius: 15px;
        font-size: 1.8em;
        font-weight:bold;
        z-index: 1;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, 0%);
        color: #00040D;
        text-shadow: -1px 0 white,
        0 1px white,
        1px 0 white,
            0 -1px white;

    }
    @media screen and (max-width: 1080px )
    {
        background: #ffffffcc;
        margin-top: 40px;
        h1{
            font-size: 1.2em;
            word-break: keep-all;
        }
    }
}
