@import "../vars.less";


//  section - Projects

@media screen and (max-width: 1080px )
{
  .SectionProjectsContainer{
    width:100%;
    margin:auto auto;
  }
  .SectionProject {
    font-size: 20px;
    margin:0 0;
    border-radius: 0px;
    backdrop-filter: none;
  }
  .SectionProject:last-child{
    padding-bottom: 80px;
  }
}
//
.SectionProject {

  // 移动端 ~1080px
  @media screen and (max-width: 1080px )
  {
    blockquote{
      margin: 0 0px;
      padding: 0 5px;
      font-size: 20px;
      border-left: 2px solid @color-iphone-blue;
      p{
        text-align: justify-all;
        color: @color-iphone-spacegray;
        line-height: 1.3em;
        margin:5px;
      }
    }

    .project-article{
      flex-direction:column;
      .smallside{
        flex-basis: calc(100% - 20px); /* 考虑一定的间距，这里假设间距为 20px，每个元素平分一半宽度减去间距的一半 */
        width:100%;
      }
      .bigside{
        flex-basis: calc(100%); /* 考虑一定的间距，这里假设间距为 20px，每个元素平分一半宽度减去间距的一半 */
        width: 100%;
      }

      .bigside.left {
        padding-right: 0px;
      }

      .bigside.right {
        padding-left:0px;
      }


    }
    .project-article.mobile-reverse{
      flex-direction: column-reverse;
    }

    .project-simplearticle{
      margin: 00px 40px;
    }
    .project-grouparticle{
      margin: 0px 40px;
      .project-icon{
        width: 75px;
        min-width: 75px;
        margin-right: 2px;
        margin-bottom: 2px;
      }
      .sidegroup-row{
        gap:0px;
        flex-direction: column;
      }
    }

    .simple-row {
      flex-direction: column;
      gap:0;
      .review{
        width:100%;
        margin-top: 10px;
      }
    }

    p {
      margin: 0px 0px 10px;
    }
    .project-head {
      margin:40px 0px 20px;

      .project-icon {
        width: 75px;
        min-width: 75px;
      }

      .project-head-text {
        h1 {
          margin: 5px 10px;
        }

        h2 {
          margin: 5px 10px;
        }

        h3 {
          margin: 3px 10px;
        }
      }
    }
  }
}
