@import "../vars.less";


//  section - Projects
.SectionProject {
  text-align: justify;
  color: #00040D;
  line-height: 1em;
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: #ffffff99;
  font-size: 26px;
  margin: 20px 0;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  width:100%;
}

.SectionProjectsContainer {
  display: flex;
  flex-direction: row;
  width: 1080px;
  margin: 24px auto;

  flex-wrap: wrap;
  justify-content: space-between;
}

//
.SectionProject {

  .hr {
    /*width: 87%;*/
    height: 2px;
    margin: 10px 20px;
    background: transparent url("../img/hr.png") 0 0 repeat-x;
  }

  h1 {
    display: block;
    font-size: 1.8em;
    line-height: 1em;
    font-weight: bold;
    padding: 0;
    margin: 20px 40px;
    word-break: break-word;
  }

  p {
    text-align: justify;
    clear: both;
    margin: 0px 0px 10px;
    font-size: 0.7em;
    color: #00040D;
    line-height: 1.8em;
  }

  ul{
    margin:10px 0 ;
  }
  li{
    margin:10px 0 10px 20px;
    list-style: disc;
    line-height: 1.5em;
  }

  .project-head {
    //background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;


    .project-icon {
      padding: 0;
      width: 100px;
      min-width: 100px;
      transition: width 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), min-width 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      margin-left: 0px;
    }

    .project-icon.round {
      border-radius: 15px;
    }

    .project-head-text {
      display: flex;
      flex-direction: column;

      h1 {
        margin: 5px 20px;
        font-size: 1.3em;
        line-height: 1em;
      }

      h2 {
        display: block;
        font-size: 0.8em;
        font-weight: bold;
        padding: 0;
        margin: 5px 20px;
      }

      h3 {
        font-weight: bold;
        font-size: 0.5em;
        color: #00040D;
        line-height: 1em;
        padding: 0;
        margin: 3px 20px;

      }
    }
  }


  blockquote {
    margin: 0;
    padding: 0 10px;
    font-size: 20px;
    border-left: 2px solid @color-iphone-blue;

    p {
      text-align: justify-all;
      color: @color-iphone-spacegray;
      line-height: 1.5em;
      margin: 5px;

      ul{
        margin:10px 0 ;
      }
      li{
        margin:10px 0 10px 20px;
        list-style: disc;
        line-height: 1.5em;
      }
    }
  }

  // 左右结构的文章
  .project-article {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 40px 40px;

    .smallside {
      flex-basis: calc(30%); /* 考虑一定的间距，这里假设间距为 20px，每个元素平分一半宽度减去间距的一半 */
      width: 30%;
      border-radius: 15px;
    }

    .bigside {
      flex-basis: calc(70%); /* 考虑一定的间距，这里假设间距为 20px，每个元素平分一半宽度减去间距的一半 */
    }


    .bigside.left {
      padding-right: 40px;
    }

    .bigside.right {
      padding-left:40px;
    }
  }

  // 竖向排布的文章
  .project-simplearticle {
    margin: 20px 40px 40px;
    .review{
      width :100%;
      height:100%;
      border-radius: 15px;
      margin-top: 20px;
    }
    .bilibili{
      width :100%;
      aspect-ratio: 16/9;
      border-radius: 15px;
    }
  }

  // 竖向排布的 多个项目一起介绍的文章
  .project-grouparticle {
    margin: 20px 40px 40px;
    .project-icon{
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0;
      width: 100px;
      min-width: 100px;
      transition: width 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), min-width 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      margin-left: 0px;
    }
    .project-icon.round {
      border-radius: 15px;
    }
    blockquote{
      margin:0px 0 40px;
    }

    //在 多个项目中，左右分组横向排列多个的话 用这个包裹
    .sidegroup-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap:40px;
      .sidegroup{
        flex:1;
      }
    }
    .review{
      max-width :100%;
      height:auto;
      border-radius: 15px;
      margin-top: 20px;
    }
  }

  .simple-row{
    width:100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    gap:10px;
    flex-shrink:1;
    .review{
      flex:1;
      width:10%;
      height:auto;
      max-width: 100%;
      border-radius: 15px;
      //flex-basis: calc(30vw - 20px);
    }
  }

}
