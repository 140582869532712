.VideoBg{
  width:100%;
  height:auto;
  left:0px;
  top:0px;
  bottom:0;
  z-index: -1;
  vertical-align:bottom;
  display:block;
  position:fixed;
}

.v1{
  width: 100%;
  height: 100%;
  object-fit:cover;

}