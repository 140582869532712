
// 下划线动画
/* 首先定义一个类，例如 animated-bold */
.animated-bold {
  position: relative;
  text-decoration: none; /* 先去掉默认的下划线 */
}

/* 使用 ::after 伪元素来创建下划线 */
.animated-bold::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0.2em; /* 调整下划线的位置 */
  width: 0; /* 初始宽度为 0 */
  height: 0.8em; /* 下划线的高度 */
  //height: 4px; /* 下划线的高度 */
  border-radius: 50% 0% 50% 0% ;
  z-index:-1;
  //background-color: #00B8EB; /* 下划线的颜色 */
  //background-color: #c10606; /* 下划线的颜色 */
  background-color: #f6eb61; /* 下划线的颜色 */
  transition: width 1s ease-out; /* 定义下划线宽度的过渡效果，持续时间 0.3 秒，缓动效果为 ease-in-out */
  transition-delay: 1s;
}

/* 当鼠标悬停时，将下划线的宽度变为 100%，实现动画效果 */
.inview.animated-bold:hover::after {
  width: 0%;
}
// 当元素进入视口时，将下划线的宽度变为 100%，实现动画效果
.inview.animated-bold::after {
  width: 100%;
}