@import "../vars.less";

.MBHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.MBHeader .MBHeaderLogo{
  position: absolute;
  left:0;right:0;
  margin: 0 auto 0 auto;
  height:auto;
  aspect-ratio: 1/1;
  top:10px;
}
.MBHeader .header-bg{
  //background: #ffffff99 url("../img/tiny_grid.png") 0 0 repeat;
  //background: #ffffffCC;
  //backdrop-filter: blur(10px);
  position:absolute;
  width:100%;
  //opacity:1;
}


.outlineWhite {
  text-shadow: -1px 0 #F9F6EF,
  0 1px #F9F6EF,
  1px 0 #F9F6EF,
    0 -1px #F9F6EF;
}


.MBHeader .info .title {
  position: absolute;
  font-size: 40px;
  line-height: 1em;
  color: @color-midnight-black;
  font-weight: bold;
  left: 0;
  right: 0;

  /*可变*/
  /*margin: 0px auto 0;*/
  /*top: 6px;*/
}

.MBHeader .info .subTitle {
  position: absolute;
  font-size: 18px;
  line-height: 1em;
  color: #666;
  margin: 0 auto;
  left: 0;
  right: 0;
  /*可变*/
  /*top: 6px;*/

}

.MBHeader .menu {
  height: 50px;
  width: 100%;
  background-color: #000000cc;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.MBHeader .menu a,.MBHeader .menu .a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  color: #F9F6EF;
  text-decoration: none;
  font-size: 24px;
  border-radius: 6px;
  //width: 48px;
  height: 24px;
  margin: 0 1px;
  overflow-x: visible;
  white-space: nowrap;
  cursor: pointer;
}

.MBHeader .menu a.active ,.MBHeader .menu .a.active{
  background-color: #0094D8;
}